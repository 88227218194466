import { lazy, Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import LoadingScreen from "./components/LoadingScreen";
import * as ROUTES from "./constants/routes";

const Dashboard = lazy(() => import("./pages/dashboard"));
const Login = lazy(() => import("./pages/login"));
const Otp = lazy(() => import("./pages/otp"));
const Register = lazy(() => import("./pages/register"));
const Chat = lazy(() => import("./pages/chat"));


const App = () => (
	<BrowserRouter>
		<Suspense
			fallback={
				<div className="w-screen h-screen">
					<LoadingScreen />
				</div>
			}>
			<Switch>
				<Route exact path={ROUTES.DASHBOARD} component={Dashboard} />
				<Route exact path={ROUTES.LOGIN} component={Login} />
				<Route exact path={ROUTES.OTP_SCREEN} component={Otp} />
				<Route exact path={ROUTES.REGISTER} component={Register} />
				<Route exact path={ROUTES.CHAT} component={Chat} />
				<Redirect to={ROUTES.LOGIN} />
			</Switch>
		</Suspense>
	</BrowserRouter>
);
export default App;
